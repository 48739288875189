import { useGraphQLClient } from 'providers';
import { TWO_HOURS_IN_MILLISECONDS, TWO_MINUTES_IN_MILLISECONDS } from 'mdc-constants';
import useAccessToken from './useAccessToken';
import { GetChecklistCommentsVariables, useGetChecklistComments } from '../graphql';

export default function useChecklistComments(checklistId?: string) {
  const variables: GetChecklistCommentsVariables = {
    id: checklistId || '',
  };

  const { data: accessToken } = useAccessToken();
  const { graphQLClient } = useGraphQLClient(accessToken);

  const queryInfo = useGetChecklistComments(
    graphQLClient,
    variables,
    {
      cacheTime: TWO_HOURS_IN_MILLISECONDS,
      staleTime: TWO_MINUTES_IN_MILLISECONDS,
      enabled: (!!accessToken && !!checklistId) && checklistId !== 'new',
      refetchOnMount: true,
      refetchOnReconnect: true,
      refetchOnWindowFocus: true,
    },
  );

  return {
    ...queryInfo,
  };
}
