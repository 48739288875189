import { useGraphQLClient } from 'providers';
import { TWO_HOURS_IN_MILLISECONDS, TWO_MINUTES_IN_MILLISECONDS } from 'mdc-constants';
import useAccessToken from './useAccessToken';
import { useQueryClient } from '@tanstack/react-query';
import { useInfiniteGetMyChecklists, GetMyChecklistsVariables, CopyChecklist, UpdateChecklist } from '../graphql';

function updateChecklistCacheAfterDeleteFn(deletedIds: string) {
  return (old: any) => ({
    ...old,
    pages: old.pages.map((page: any) => ({
      ...page,
      myChecklists: {
        ...page.myChecklists,
        data: page.myChecklists.data.filter((myChecklist: any) => !deletedIds.includes(myChecklist.id)),
      },
    })),
  });
}

function copyCurrentItem(cItem: CopyChecklist['copyChecklist'], cIndex: number, allData: any) {
  if (cIndex === 0) {
    return [cItem, ...allData];
  }

  return allData;
}

function updateChecklistCacheAfterCopyFn(copiedItem: CopyChecklist['copyChecklist']) {
  return (old: any) => ({
    ...old,
    pages: old.pages.map((page: any, index: number) => ({
      ...page,
      myChecklists: {
        ...page.myChecklists,
        data: copyCurrentItem(copiedItem, index, page.myChecklists.data),
      },
    })),
  });
}

function updateChecklistCacheAfterUpdateFn(updatedItem: UpdateChecklist['updateChecklist']) {
  return (old: any) => ({
    ...old,
    pages: old.pages.map((page: any) => ({
      ...page,
      myChecklists: {
        ...page.myChecklists,
        data: page.myChecklists.data.map((myChecklist: any) => {
          if (myChecklist.id === updatedItem?.id) {
            return updatedItem;
          }

          return myChecklist;
        }),
      },
    })),
  });
}

export default function useInfiniteMyChecklists(variables: GetMyChecklistsVariables, searchQuery: string | null) {
  const { data: accessToken } = useAccessToken();
  const { graphQLClient } = useGraphQLClient(accessToken);
  const queryClient = useQueryClient();

  const queryInfo = useInfiniteGetMyChecklists('page', graphQLClient, variables, {
    cacheTime: TWO_HOURS_IN_MILLISECONDS,
    staleTime: TWO_MINUTES_IN_MILLISECONDS,
    suspense: false,
    enabled: !!accessToken,
    getNextPageParam: ({ myChecklists }) => {
      const { currentPage, hasMorePages } = myChecklists?.paginatorInfo || {};

      if (hasMorePages && currentPage) {
        return currentPage;
      }

      return undefined;
    },
  });

  return {
    ...queryInfo,

    updateMyChecklistCacheAfterDelete: (deleteIds: string) => {
      queryClient.cancelQueries(['getMyChecklists.infinite', { keyword: searchQuery || '' }]);
      queryClient.setQueryData(
        ['getMyChecklists.infinite', { keyword: searchQuery || '' }],
        updateChecklistCacheAfterDeleteFn(deleteIds),
      );
    },
    updateMyChecklistCacheAfterCopy: (copiedItem: CopyChecklist['copyChecklist']) => {
      queryClient.cancelQueries(['getMyChecklists.infinite', { keyword: searchQuery || '' }]);
      queryClient.setQueryData(
        ['getMyChecklists.infinite', { keyword: searchQuery || '' }],
        updateChecklistCacheAfterCopyFn(copiedItem),
      );
    },
    updateChecklistCacheAfterUpdate: (updatedItem: UpdateChecklist['updateChecklist']) => {
      queryClient.cancelQueries(['getMyChecklists.infinite', { keyword: searchQuery || '' }]);
      queryClient.setQueryData(
        ['getMyChecklists.infinite', { keyword: searchQuery || '' }],
        updateChecklistCacheAfterUpdateFn(updatedItem),
      );
    },
  };
}
