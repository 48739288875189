import { useGraphQLClient } from 'providers';
import useAccessToken from './useAccessToken';
import { useFollowChecklist } from '../graphql';

export default function useFollowChecklistMutation() {
  const { data: accessToken } = useAccessToken();
  const { graphQLClient } = useGraphQLClient(accessToken);

  const queryInfo = useFollowChecklist(graphQLClient);

  return {
    ...queryInfo,
  };
}
