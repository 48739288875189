import { TWO_MINUTES_IN_MILLISECONDS } from 'mdc-constants';
import { useGraphQLClient } from 'providers';
import { useGetAllLogs, GetAllLogsVariables } from '../graphql';
import useAccessToken from './useAccessToken';

export function useAllLogs(variables?: GetAllLogsVariables) {
  const { data: accessToken } = useAccessToken();
  const { graphQLClient } = useGraphQLClient(accessToken);

  const queryInfo = useGetAllLogs(
    graphQLClient,
    variables,
    {
      // cacheTime: TWO_HOURS_IN_MILLISECONDS,
      staleTime: TWO_MINUTES_IN_MILLISECONDS,
      enabled: !!accessToken,
      refetchOnMount: true,
      refetchOnWindowFocus: 'always',
      refetchOnReconnect: true,
    },
  );

  return {
    ...queryInfo,
  };
}
