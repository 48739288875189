import { DownOutlined, RightOutlined } from '@ant-design/icons';
import { Grid, Menu, MenuProps } from 'antd';
import { SubMenuType } from 'antd/lib/menu/hooks/useItems';
import cn from 'classnames';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { FC, useMemo, useState } from 'react';
import styles from 'sailor-ui/styles/Header.module.scss';
import { FeaturesMenu } from './FeaturesMenu';
import { MainDrawer } from './MainDrawer';
import { menuKeys } from './menuKeys';
import { MoreMenu } from './MoreMenu';
import { BlogsMenu } from './BlogMenu';
import { BoatsMenu } from './BoatsMenu';
import { useMoreMenuItems } from './MoreMenu/useMoreMenuItems';
import { FeaturesMenuItems } from './FeaturesMenu/FeaturesMenu.component';
// import BoatsMenuDesktop from './BoatsMenu/Desktop';

const LeftMenu: FC = () => {
  const router = useRouter();
  const { xl: responsive } = Grid.useBreakpoint();
  const moreMenuItems = useMoreMenuItems();
  const featuresMenuItems = FeaturesMenuItems();

  const [moreDrawer, setMoreDrawer] = useState<boolean>(false);
  const [featureDrawer, setFeatureDrawer] = useState<boolean>(false);
  const [boatDrawer, setBoatDrawer] = useState<boolean>(false);
  const [blogDrawer, setBlogDrawer] = useState<boolean>(false);

  const selectedKeys = useMemo(() => {
    const activeMenuKey = menuKeys.find((item) => item.validPaths.some((path) => path === router.pathname));

    if (!activeMenuKey) {
      const itemStartsWith =
        menuKeys.find((item) => {
          if (item?.startsWith) {
            return router.pathname.startsWith(item?.startsWith);
          }

          return undefined;
        })?.key || '/no-match';

      return itemStartsWith;
    }

    return activeMenuKey?.key || '/no-match';
  }, [router.pathname]);

  const menuItems = useMemo((): MenuProps['items'] => {
    const mouseHandler: SubMenuType['onMouseEnter'] = (e) => {
      e.domEvent.preventDefault();
    };

    const CANCEL_DEFAULT_MOUSE_EVENTS: Pick<SubMenuType, 'onMouseEnter' | 'onMouseLeave'> = {
      onMouseEnter: mouseHandler,
      onMouseLeave: mouseHandler,
    };

    if (responsive) {
      return [
        {
          key: 'boats',
          label: <BoatsMenu key="boats" />,
          ...CANCEL_DEFAULT_MOUSE_EVENTS,
        },
        // {
        //   key: 'discover',
        //   label: (
        //     <Link href="/discover">
        //       <a className="ant-menu-item--badge">
        //         {f('discover.title')}
        //         <span className="menuItemBadge">{f('discover.badge')}</span>
        //       </a>
        //     </Link>
        //   ),
        // },
        {
          key: 'maker',
          label: (
            <Link href="/maker" className="ant-menu-item--badge">
              Makers
            </Link>
          ),
        },
        {
          key: 'blogs',
          label: <BlogsMenu key="blogs-desktop" />,
          ...CANCEL_DEFAULT_MOUSE_EVENTS,
        },

        {
          key: 'features',
          label: (
            <Link href="/features">
              <span>
                Features <DownOutlined />
              </span>
            </Link>
          ),
          children: featuresMenuItems,
          popupClassName: styles.subMenuDropdown,
        },
        {
          key: 'more',
          label: (
            <span>
              More <DownOutlined />
            </span>
          ),
          children: moreMenuItems,
          popupClassName: styles.subMenuDropdown,
        },
      ];
    }

    return [
      {
        key: 'boats',
        style: {
          display: 'flex',
          justifyContent: 'space-between',
        },
        onClick: () => {
          setBoatDrawer(true);
        },
        label: (
          <>
            Boats <RightOutlined />
          </>
        ),
      },
      // {
      //   key: 'discover',
      //   label: (
      //     <Link href="/discover">
      //       <a className="ant-menu-item--badge">
      //         {f('discover.title')}
      //         <span className="menuItemBadge">{f('discover.badge')}</span>
      //       </a>
      //     </Link>
      //   ),
      // },
      {
        key: 'maker',
        label: (
          <Link href="/maker" className="ant-menu-item--badge">
            Maker
          </Link>
        ),
      },
      {
        key: 'blogs',
        label: (
          <>
            Blogs
            <RightOutlined />
          </>
        ),
        onClick: () => {
          setBlogDrawer(true);
        },
      },
      {
        key: 'more-menu-trigger',
        onClick: () => {
          setMoreDrawer(true);
        },
        label: (
          <>
            More
            <RightOutlined />
          </>
        ),
      },
      {
        key: 'feature-menu-trigger',
        onClick: () => {
          setFeatureDrawer(true);
        },
        label: (
          <>
            Features <RightOutlined />
          </>
        ),
      },
    ];
  }, [responsive, featuresMenuItems, moreMenuItems]);

  return (
    <>
      <Menu
        mode={responsive ? 'horizontal' : 'inline'}
        selectedKeys={[selectedKeys]}
        className={cn({ [styles.leftMenuDesktop]: responsive })}
        items={menuItems}
      />

      <MainDrawer
        visible={moreDrawer}
        onClose={() => {
          setMoreDrawer(false);
        }}
        className={styles.normalDrawer}
        closable={false}
      >
        <MoreMenu />
      </MainDrawer>

      <MainDrawer
        visible={featureDrawer}
        onClose={() => {
          setFeatureDrawer(false);
        }}
        className={styles.normalDrawer}
        closable={false}
      >
        <FeaturesMenu />
      </MainDrawer>

      <MainDrawer
        visible={boatDrawer}
        onClose={() => {
          setBoatDrawer(false);
        }}
        closable={false}
        className={styles.normalDrawer}
      >
        <BoatsMenu />
      </MainDrawer>

      <MainDrawer
        visible={blogDrawer}
        onClose={() => {
          setBlogDrawer(false);
        }}
        closable={false}
        className={styles.normalDrawer}
      >
        <BlogsMenu key="blogs-mobile" />
      </MainDrawer>
    </>
  );
};

export default LeftMenu;
