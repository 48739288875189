import { ReactNode } from 'react';
import {
  CustomerServiceOutlined,
  QuestionCircleOutlined,
  FileTextOutlined,
  BugOutlined,
  FrownOutlined,
  MailOutlined,
  StarOutlined,
} from '@ant-design/icons';
import { CommunityIcon } from '../CustomIcons/CustomIcons';

type HelpLink = {
  id: string;
  transId: string;
  icon: ReactNode;
};

export const helpLinks: HelpLink[] = [
  {
    id: 'support',
    transId: '0',
    icon: <CustomerServiceOutlined />,
  },
  {
    id: 'faq',
    transId: '1',
    icon: <QuestionCircleOutlined />,
  },
  {
    id: 'tutorials',
    transId: '2',
    icon: <FileTextOutlined />,
  },
  {
    id: 'community',
    transId: '3',
    icon: <CommunityIcon />,
  },
  {
    id: 'bug',
    transId: '4',
    icon: <BugOutlined />,
  },
  {
    id: 'wrongdata',
    transId: '5',
    icon: <FrownOutlined />,
  },
  {
    id: 'feature',
    transId: '6',
    icon: <StarOutlined />,
  },
  {
    id: 'contactus',
    transId: '7',
    icon: <MailOutlined />,
  },
];
