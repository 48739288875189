import { TWO_HOURS_IN_MILLISECONDS, TWO_MINUTES_IN_MILLISECONDS } from 'mdc-constants';
import { useGraphQLClient } from 'providers';
import { useMemo } from 'react';
import useUrlState from '@ahooksjs/use-url-state';
import { InitialDataFunction, useQueryClient } from '@tanstack/react-query';
import { useGetAlert, GetAlertVariables, GetAlerts, GetAlert } from '../graphql';
import useAccessToken from './useAccessToken';

export default function useAlert(itemAlertId?: string, initialData?: InitialDataFunction<GetAlert>) {
  const queryClient = useQueryClient();
  const { data: accessToken } = useAccessToken();
  const { graphQLClient } = useGraphQLClient(accessToken);
  const [urlState] = useUrlState();

  const variables: GetAlertVariables = {
    id: itemAlertId || '',
  };

  const localInitialData = useMemo<GetAlert | undefined>(() => {
    const alertsQueries = queryClient.getQueriesData<GetAlerts>([
      'getAlerts',
      {
        first: parseInt(urlState.perPage, 10) || 15,
        keyword: urlState.query || '',
        page: urlState?.page || 1,
      },
    ]);

    // console.log('what we got --', alertsQueries);

    let initialAlertData;

    for (let i = 0; i < alertsQueries.length; i += 1) {
      const [f, alertData] = alertsQueries[i];
      const alert = alertData?.alerts?.data.find((item) => item.id === variables.id);

      if (alert) {
        initialAlertData = { alert };
        break;
      }
    }

    // console.log('The initial alert data', initialAlertData);

    return initialAlertData;
  }, [queryClient, variables.id, urlState]);

  const queryInfo = useGetAlert(graphQLClient, variables, {
    cacheTime: TWO_HOURS_IN_MILLISECONDS,
    staleTime: TWO_MINUTES_IN_MILLISECONDS,
    // keepPreviousData: true,
    enabled: !!accessToken && !!itemAlertId,
    refetchOnMount: true,
    refetchOnReconnect: true,
    refetchOnWindowFocus: true,
    initialData: localInitialData || initialData,
    // initialDataUpdatedAt:,
    onSuccess: () => {
      // console.log(`Fetching trip details: [${variables.id}] succeed`);
    },
  });

  return {
    ...queryInfo,
  };
}
