import { useGraphQLClient } from 'providers';
import useAccessToken from './useAccessToken';
import { useUpdateChecklist } from '../graphql';

export default function useUpdateChecklistMutation() {
  const { data: accessToken } = useAccessToken();
  const { graphQLClient } = useGraphQLClient(accessToken);

  const mutationInfo = useUpdateChecklist(graphQLClient);

  return {
    ...mutationInfo,
  };
}
