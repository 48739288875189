import { useGraphQLClient } from 'providers';
import { message } from 'antd';
import { useQueryClient } from '@tanstack/react-query';
import useAccessToken from './useAccessToken';
import { useBlockChecklistFollower } from '../graphql';

export default function useBlockChecklistFollowerMutation(
  toggleUsersModal: React.Dispatch<React.SetStateAction<boolean>>,
  userName: string,
  checklistId: string | undefined,
) {
  const { data: accessToken } = useAccessToken();
  const { graphQLClient } = useGraphQLClient(accessToken);
  const queryClient = useQueryClient();

  const mutationInfo = useBlockChecklistFollower(graphQLClient, {
    onSuccess: () => {
      message.success(`${userName} is successfully blocked`);
      toggleUsersModal(false);
      queryClient.invalidateQueries(['getChecklistFollowers', { id: checklistId }]);
      queryClient.invalidateQueries(['getChecklistBlockedFollowers', { id: checklistId }]);
    },
    onError: () => {
      message.error('Block failed');
    },
  });

  return mutationInfo;
}
