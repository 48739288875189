/**
 *
 * @param url to remove end slash
 * @returns new url without end slash
 */
export function removeEndSlash(url:string) {
  if (url.substr(-1) === '/') {
    return url.substr(0, url.length - 1);
  }

  return url;
}
