import { useGraphQLClient } from 'providers';
import { message } from 'antd';
import { useQueryClient } from '@tanstack/react-query';
import useAccessToken from './useAccessToken';
import { useUnblockChecklistFollower } from '../graphql';

export default function useUnblockChecklistFollowerMutation(
  toggleUsersModal: React.Dispatch<React.SetStateAction<boolean>>,
  userName: string,
  checklistId: string | undefined,
) {
  const { data: accessToken } = useAccessToken();
  const { graphQLClient } = useGraphQLClient(accessToken);
  const queryClient = useQueryClient();

  const mutationInfo = useUnblockChecklistFollower(graphQLClient, {
    onSuccess: () => {
      message.success(`${userName} is successfully unblocked`);
      toggleUsersModal(false);
      queryClient.invalidateQueries(['getChecklistFollowers', { id: checklistId }]);
      queryClient.invalidateQueries(['getChecklistBlockedFollowers', { id: checklistId }]);
    },
    onError: () => {},
  });

  return mutationInfo;
}
