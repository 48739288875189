import { useGraphQLClient } from 'providers';
import useAccessToken from './useAccessToken';
import { useEditChecklistComment } from '../graphql';

export default function useEditChecklistCommentMutation() {
  const { data: accessToken } = useAccessToken();
  const { graphQLClient } = useGraphQLClient(accessToken);

  const mutationInfo = useEditChecklistComment(graphQLClient);

  return {
    ...mutationInfo,
  };
}
