import { TWO_HOURS_IN_MILLISECONDS, TWO_MINUTES_IN_MILLISECONDS } from 'mdc-constants';
import { useGraphQLClient } from 'providers';
import { GetAllLogsVariables, useInfiniteGetAllLogs } from '../graphql';
import useAccessToken from './useAccessToken';

export default function useInfiniteLogs(variables?: GetAllLogsVariables) {
  const { data: accessToken } = useAccessToken();
  const { graphQLClient } = useGraphQLClient(accessToken);

  const queryInfo = useInfiniteGetAllLogs('page', graphQLClient, variables, {
    cacheTime: TWO_HOURS_IN_MILLISECONDS,
    staleTime: TWO_MINUTES_IN_MILLISECONDS,
    suspense: false,
    enabled: !!accessToken && !!variables?.expense_id,
    getNextPageParam: ({ flatLogs }) => {
      const { currentPage, hasMorePages } = flatLogs?.paginatorInfo || {};

      if (hasMorePages && currentPage) {
        return currentPage + 1;
      }

      return undefined;
    },
  });

  return {
    ...queryInfo,
  };
}
