/* eslint-disable no-underscore-dangle */
import axios from 'axios';

export interface RawWordPressPost {
  id: number
  date: string
  slug:string
  link:string
  title:{
    rendered:string
  }
  excerpt:{
    rendered:string
  }
  _embedded:{
    'wp:featuredmedia':{
      alt_text:string
      source_url:string
      media_details:{
        sizes:{
          thumbnail:{
            source_url: string
          }
          medium:{
            source_url: string
          }
        }
      }
    }[]
    'wp:term':[{
      id:number
      name:string
    }][]
  }
}

export interface FetchPosts {
  url: string;
  items: PostItem[];
}

export interface PostItem {
  id: number;
  title: string;
  categories?: {
    name:string
    id:number
  }[];
  desc?: string;
  date?:string;
  image?: {
    src: string;
    alt: string;
    src_medium: string;
    source_url: string;
  };
  url?: string;
}

/**
 * Fetch Wordpress Posts
 *
 * @param {string} type Posts Type Blog/News/Personas
 * @param {string} title Type title
 * @param {string} moreUrl More Posts Url
 * @param {number} limit Post per page limit
 *
 *  @returns {object} Posts
 */

const fetchBlogPosts = async (
  type: 'posts' | 'news' | 'personas',
  limit: number = 2,
): Promise<FetchPosts> => {
  const mainUrl = `${process.env.NEXT_PUBLIC_WORDPRESS_URL}${type}`;

  // eslint-disable-next-line max-len
  const url = `${process.env.WORDPRESS_API_URL}/${type}?_fields=date,id,link,slug,title,excerpt,_links&per_page=${limit}&_embed`;

  try {
    const { data } = await axios.get<RawWordPressPost[]>(url);

    return await Promise.resolve({
      url: mainUrl,
      items: data.map((post) => ({
        id: post.id,
        title: post.title.rendered,
        categories: post._embedded['wp:term'][0].map((category) => ({ name: category.name, id: category.id })),
        desc: post.excerpt.rendered,
        date: post.date,
        image: {
          src:
              post._embedded['wp:featuredmedia']
              && post._embedded['wp:featuredmedia'][0].media_details.sizes
                .thumbnail.source_url,
          src_medium:
              post._embedded['wp:featuredmedia']
              && post._embedded['wp:featuredmedia'][0].media_details.sizes.medium
                .source_url,
          alt:
              post._embedded['wp:featuredmedia']
              && post._embedded['wp:featuredmedia'][0].alt_text,
          source_url:
              post._embedded['wp:featuredmedia']
              && post._embedded['wp:featuredmedia'][0].source_url,
        },
        url: post.link,
      })),
    });
  } catch (err) {
    return Promise.reject(err);
  }
};

export { fetchBlogPosts };
