import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { fetchBlogPosts } from '../rest-api/fetchBlogPosts';
import { useQueryStatusLogging } from './useQueryStatusLogging';

interface FetchBlogPostsVariables {
  type: 'posts' | 'news' | 'personas';
  limit: number;
}

export default function useBlogPosts(variables: FetchBlogPostsVariables, options: UseQueryOptions = {}) {
  const { type, limit } = variables;
  const queryInfo = useQuery(['fetchBlogPosts', { type, limit }], () => fetchBlogPosts(type, limit), {
    keepPreviousData: true,
    enabled: options.enabled,
    onSuccess: () => {
      console.log(Date.now(), 'Fetching blog posts succeed');
    },
  });

  useQueryStatusLogging(queryInfo, 'fetchBlogPosts');

  return queryInfo;
}
