import { Alert, Button } from 'antd';
import { FC } from 'react';

interface AlertAnnouncementProps {
  closable: boolean;
  announcementData: any;
  handleAlertClose: () => void;
  alertType: 'success' | 'info' | 'warning' | 'error' | undefined;
}

const AlertAnnouncement: FC<AlertAnnouncementProps> = ({ closable, announcementData, handleAlertClose, alertType }) => (
  <div>
    <Alert
      type={alertType}
      message={announcementData?.title}
      description={<div dangerouslySetInnerHTML={{ __html: announcementData?.description }} />}
      closable={closable}
      action={
        announcementData?.action_title ? (
          <a href={`${announcementData?.action_link || ''}`} target="_blank" rel="noopener noreferrer">
            <Button size="small" type="primary">
              {announcementData?.action_title}
            </Button>
          </a>
        ) : null
      }
      onClose={() => handleAlertClose()}
    />
  </div>
);

export default AlertAnnouncement;
