import { useCallback } from 'react';
import useAccessToken from './useAccessToken';

export default function useFilesUrlWithToken() {
  const { data: accessToken } = useAccessToken();

  /**
   * Used to access protected file and images
   * @param src original file url
   * @returns url with the access token as query parameter
   */
  const fileUrlWithToken = useCallback((src?:string | null):string | null => {
    if (src) {
      return `${src}?token=${accessToken}`;
    }

    return null;
  }, [accessToken]);

  return {
    fileUrlWithToken,
  };
}
