import useUrlState from '@ahooksjs/use-url-state';
import {
  TASKS_DISABLED_BOAT_RECORDS,
  TASKS_TABLE_ORDER,
  TWO_HOURS_IN_MILLISECONDS,
  TWO_MINUTES_IN_MILLISECONDS,
} from 'mdc-constants';
import { useGraphQLClient } from 'providers';
import { useMemo } from 'react';
import { QueryKey, useQueryClient, UseQueryOptions } from '@tanstack/react-query';
import { GetTask, GetTasks, GetTaskVariables, useGetTask } from '../graphql';
import useAccessToken from './useAccessToken';
import { useLocalStorageState } from 'ahooks';

export default function useTask(
  itemTaskId?: string,
  options?: UseQueryOptions<GetTask, unknown, GetTask, QueryKey> | undefined,
) {
  const queryClient = useQueryClient();
  const { data: accessToken } = useAccessToken();
  const { graphQLClient } = useGraphQLClient(accessToken);
  const [urlState] = useUrlState();
  const [disabledBoatRecords] = useLocalStorageState<boolean>(TASKS_DISABLED_BOAT_RECORDS);
  const [storedOrderVariables] = useLocalStorageState<{ column: string; order: string }[]>(TASKS_TABLE_ORDER);

  const variables: GetTaskVariables = {
    id: itemTaskId || '',
  };

  const localInitialData = useMemo(() => {
    const tasksQueries = queryClient.getQueriesData<GetTasks>([
      'getTasks',
      {
        first: parseInt(urlState.perPage, 10) || 25,
        keyword: urlState.query || null,
        page: parseInt(urlState?.page, 10) || 1,
        orderBy: storedOrderVariables || [{ column: 'id', order: 'desc' }],
        with_disabled: disabledBoatRecords || false,
      },
    ]);

    let initialTaskData;

    for (let i = 0; i < tasksQueries.length; i += 1) {
      const [f, taskData] = tasksQueries[i];
      const task = taskData?.tasks?.data.find((item) => item.id === variables.id);

      if (task) {
        initialTaskData = { task };
        break;
      }
    }

    // console.log('The initial alert data', initialTaskData);

    return initialTaskData;
  }, [queryClient, variables.id, urlState, disabledBoatRecords, storedOrderVariables]);

  const queryInfo = useGetTask(graphQLClient, variables, {
    ...options,
    cacheTime: TWO_HOURS_IN_MILLISECONDS,
    staleTime: TWO_MINUTES_IN_MILLISECONDS,
    enabled: !!accessToken && !!itemTaskId,
    refetchOnMount: true,
    refetchOnReconnect: true,
    refetchOnWindowFocus: true,
    initialData: (localInitialData as GetTask | undefined) || options?.initialData,
  });

  return {
    ...queryInfo,
  };
}
