/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import {
  HeartOutlined,
  LaptopOutlined,
  LogoutOutlined,
  QuestionOutlined,
  SettingOutlined,
  UserOutlined,
} from '@ant-design/icons';
import { useAuth0 } from '@auth0/auth0-react';
import { BoatIcon } from '@icons';
import styles from '@styles/Header/user-drawer-nav.module.scss';
import Link from 'next/link';
import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { ExternalLinkIcon } from 'sailor-ui/CustomIcons';
import { HelpCenterMenu } from 'sailor-ui/HelpCenter';
import { useCurrentUser } from 'services/hooks';
import { MainDrawer } from '../MainDrawer';
import UserAvatar from '../UserAvatar';
import { getAppConfiguration } from '@app/appConfiguration';

const config = getAppConfiguration();

const UserDrawerNav: React.FC = () => {
  const { formatMessage } = useIntl();
  const f = (id: string): string => formatMessage({ id: `header.menu.user.nav.${id}` });
  const { logout } = useAuth0();

  const handleLogout = () => {
    const returnTo = config.auth0LogoutReturnTo;

    logout({ returnTo });
  };

  const [helpCenterDrawer, setHelpCenterDrawer] = useState(false);
  const { data: userData } = useCurrentUser();

  const showDrawer = (): void => {
    setHelpCenterDrawer(true);
  };

  const onClose = (): void => {
    setHelpCenterDrawer(false);
  };

  const menuItems = [
    {
      key: 'profile',
      url: config.mdcAccountUrl,
      icon: <UserOutlined />,
      text: f('profile'),
      onClick: null,
      target: '_blank',
      isNextLink: false,
    },
    {
      key: 'user-settings',
      url: `${config.mdcAccountUrl}/settings`,
      icon: <SettingOutlined />,
      text: f('settings'),
      onClick: null,
      border: true,
      isNextLink: false,
      target: '_blank',
    },
    // {
    //   key: 'notifications',
    //   url: '/notifications',
    //   icon: <BellOutlined />,
    //   text: f('notifications'),
    //   onClick: null,
    //   isNextLink: true,
    // },
    {
      key: 'help_center',
      url: null,
      icon: <QuestionOutlined />,
      text: f('help_center'),
      onClick: showDrawer,
      border: true,
      isNextLink: false,
    },
    {
      key: 'activity-log',
      url: '/activity-log',
      icon: <LaptopOutlined />,
      text: f('activity_log'),
      onClick: null,
      border: true,
      isNextLink: true,
    },
    {
      key: 'my-boats',
      url: `${config.theboatAppUrl}/my-boats`,
      icon: <BoatIcon />,
      text: f('my_boats'),
      onClick: null,
      target: '_blank',
      isNextLink: false,
    },
    {
      key: 'favourite-boats',
      url: '/favourite-boats',
      icon: <HeartOutlined />,
      text: f('favourite_boats'),
      onClick: null,
      border: true,
      isNextLink: true,
    },
    {
      key: 'log_out',
      url: null,
      icon: <LogoutOutlined />,
      text: f('log_out'),
      onClick: handleLogout,
      border: true,
      isNextLink: false,
    },
  ];

  return (
    <>
      <div className={styles.user__nav__container}>
        <div className={styles.nav__head}>
          <div className={styles.user__detail}>
            <UserAvatar />

            <p className={styles.user__name}>{userData?.authUser?.display_name}</p>
          </div>

          {/* <div className={styles.user__trail}>
            <GoldButton />
            <TrailLeftDays />
          </div> */}
        </div>

        <ul className={styles.nav__menu}>
          {menuItems.map((item) => {
            const { key, url, icon, text, onClick, border, target, isNextLink } = item;

            if (onClick) {
              return (
                <li
                  onClick={onClick}
                  key={key}
                  className={`${styles.menu__item} ${border ? styles.menu__item___border : ''}`}
                >
                  <a>
                    {icon} {text}
                  </a>
                </li>
              );
            }

            if (!isNextLink) {
              return (
                <li key={key} className={`${styles.menu__item} ${border ? styles.menu__item___border : ''}`}>
                  <a href={url} target={target || '_self'} style={{ display: 'flex' }}>
                    {icon} {text} {target === '_blank' && <ExternalLinkIcon style={{ marginLeft: 'auto' }} />}
                  </a>
                </li>
              );
            }

            return (
              <li key={key} className={`${styles.menu__item} ${border ? styles.menu__item___border : ''}`}>
                <Link href={url}>
                  {icon} {text}
                </Link>
              </li>
            );
          })}
        </ul>
      </div>

      <MainDrawer visible={helpCenterDrawer} onClose={onClose} closable={false}>
        <HelpCenterMenu isDrawer app="tbd" />
      </MainDrawer>
    </>
  );
};

export default UserDrawerNav;
