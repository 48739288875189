import { useMemo } from 'react';
import dayjs, { Dayjs } from 'dayjs';
import useCurrentUser from './useCurrentUser';

export const usePlans = () => {
  const { data: userData, isLoading } = useCurrentUser();

  const isSubscribed = useMemo<boolean>(
    () =>
      userData?.authUser?.subscription?.highest_subscription
        ? userData?.authUser?.subscription?.highest_subscription !== 'free'
          ? true
          : false
        : false ||
          userData?.authUser?.subscription?.is_on_generic_trial === true ||
          userData?.authUser?.subscription?.is_beta_user === true,
    [
      userData?.authUser?.subscription?.highest_subscription,
      userData?.authUser?.subscription?.is_on_generic_trial,
      userData?.authUser?.subscription?.is_beta_user,
    ],
  );

  const isFreeUser: boolean = useMemo<boolean>(() => !isSubscribed, [isSubscribed]);

  const isTrialUser = useMemo<boolean>(
    () =>
      userData?.authUser?.subscription?.trial_ends_at && userData?.authUser?.subscription?.is_on_generic_trial === true,
    [userData?.authUser?.subscription?.is_on_generic_trial, userData?.authUser?.subscription?.trial_ends_at],
  );

  const trialEndsAt = useMemo<Dayjs>(
    () => dayjs(userData?.authUser?.subscription?.trial_ends_at),
    [userData?.authUser?.subscription?.trial_ends_at],
  );

  const isBetaUser = useMemo(
    () => userData?.authUser?.subscription?.is_beta_user === true,
    [userData?.authUser?.subscription?.is_beta_user],
  );

  const user = useMemo(() => userData?.authUser, [userData?.authUser]);

  return {
    isBetaUser,
    isFreeUser,
    isSubscribed,
    isTrialUser,
    trialEndsAt,
    user,
    isLoading,
  };
};
