import { TWO_HOURS_IN_MILLISECONDS, TWO_MINUTES_IN_MILLISECONDS } from 'mdc-constants';
import { useGraphQLClient } from 'providers';
import { notification } from 'antd';
import { GetChecklistVariables, useGetChecklist } from '../graphql';
import useAccessToken from './useAccessToken';

export default function useChecklist(
  checklistId: string,
  setSelectedChecklist: React.Dispatch<any>,
  setChecklistImage: React.Dispatch<any>,

) {
  const { data: accessToken } = useAccessToken();
  const { graphQLClient } = useGraphQLClient(accessToken);

  const variables: GetChecklistVariables = {
    id: checklistId,
  };

  const queryInfo = useGetChecklist(graphQLClient, variables, {
    cacheTime: TWO_HOURS_IN_MILLISECONDS,
    // staleTime: TWO_MINUTES_IN_MILLISECONDS,
    enabled: (!!accessToken && !!checklistId) && checklistId !== 'new',
    refetchOnMount: true,
    refetchOnReconnect: true,
    refetchOnWindowFocus: true,
    onSuccess: (response) => {
      setSelectedChecklist(response.checklist);
      setChecklistImage(response.checklist?.image);
    },
    onError: () => {
      notification.error({
        message: 'Error',
        description: 'Something went wrong. Data can not be loaded from server.',
      });
    },
  });

  return {
    ...queryInfo,
  };
}
