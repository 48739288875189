import { useGraphQLClient } from 'providers';
import { TWO_HOURS_IN_MILLISECONDS, TWO_MINUTES_IN_MILLISECONDS } from 'mdc-constants';
import useAccessToken from './useAccessToken';
import { GetChecklistBlockedFollowersVariables, useGetChecklistBlockedFollowers } from '../graphql';

export default function useChecklistBlockedFollowers(checklistId: string, belongsToCurrentUser: boolean | undefined) {
  const { data: accessToken } = useAccessToken();
  const { graphQLClient } = useGraphQLClient(accessToken);

  const variables: GetChecklistBlockedFollowersVariables = {
    id: checklistId || '',
  };

  const queryInfo = useGetChecklistBlockedFollowers(graphQLClient, variables, {
    cacheTime: TWO_HOURS_IN_MILLISECONDS,
    staleTime: TWO_MINUTES_IN_MILLISECONDS,
    enabled: (!!accessToken && !!checklistId) && belongsToCurrentUser,
    refetchOnMount: true,
    refetchOnReconnect: true,
    refetchOnWindowFocus: true,
  });

  return queryInfo;
}
