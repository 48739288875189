import { TWO_HOURS_IN_MILLISECONDS, TWO_MINUTES_IN_MILLISECONDS } from 'mdc-constants';
import { useGraphQLClient } from 'providers';
import useAccessToken from '../useAccessToken';
import { GetFoldersVariables, GetPaginatedFiles, GetPaginatedFilesVariables, useGetFile } from '../../graphql';
import { useQueryClient } from '@tanstack/react-query';
import { useMemo } from 'react';

export default function useFile(
  fileId?: string,
  filesQueryVariables?: GetPaginatedFilesVariables & GetFoldersVariables,
) {
  const { data: accessToken } = useAccessToken();
  const { graphQLClient } = useGraphQLClient(accessToken);
  const queryClient = useQueryClient();

  const localInitialData = useMemo(() => {
    const filesQueries = queryClient.getQueriesData<GetPaginatedFiles>(['getPaginatedFiles', filesQueryVariables]);

    let initialFileData;

    for (let i = 0; i < filesQueries?.length; i += 1) {
      const [f, fileData] = filesQueries[i];

      const file = fileData?.files?.data?.find((item) => item.id === fileId);

      if (file) {
        initialFileData = { file };
        break;
      }
    }

    return initialFileData;
  }, [filesQueryVariables, queryClient, fileId]);

  const queryInfo = useGetFile(
    graphQLClient,
    {
      id: fileId,
    },
    {
      cacheTime: TWO_HOURS_IN_MILLISECONDS,
      staleTime: TWO_MINUTES_IN_MILLISECONDS,
      // keepPreviousData: true,
      enabled: !!accessToken && !!fileId,
      refetchOnMount: true,
      refetchOnReconnect: true,
      refetchOnWindowFocus: true,
      initialData: localInitialData,
    },
  );

  return queryInfo;
}
