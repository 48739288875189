import { RightOutlined } from '@ant-design/icons';
import { Menu, MenuProps } from 'antd';
import cn from 'classnames';
import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';
import styles from './HelpCenter.module.scss';
import { helpLinks } from './helpLinks';

export type HelpCenterMenuProps = {
  isDrawer?: boolean;
  className?: string;
  app?: 'mdc' | 'tba' | 'tbd';
  noGroup?: boolean;
};

export const HelpCenterMenu: React.FC<HelpCenterMenuProps> = ({
  app, isDrawer, className, noGroup,
}) => {
  const { formatMessage } = useIntl();
  const f = (id: string): string => formatMessage({ id: `header.menu.help_and_support.${id}` });

  const menuItems = useMemo((): MenuProps['items'] => helpLinks.map((item) => {
    const url = app ? f(`item.${item.transId}.${app}.url`) : f(`item.${item.transId}.url`);
    const title = f(`item.${item.transId}.title`);
    const description = f(`item.${item.transId}.text`);

    return ({
      key: item.id,
      label: (
        <a
          href={url}
          target="_blank"
          rel="noreferrer"
        >
          {isDrawer ? title : (
            <div className={styles.itemWrapper}>
              <div className={styles.itemContent}>
                <p className={styles.itemTitle}>{title}</p>
                <p>{description}</p>
              </div>
              <RightOutlined />
            </div>
          )}
        </a>
      ),
      icon: item.icon,
      className: styles.menuItem,
    });
  }), [isDrawer]);

  const menuItemsGroup = useMemo((): MenuProps['items'] => [{
    label: f('title'),
    key: 'help-center',
    type: 'group',
    children: menuItems,
  }], [menuItems]);

  return (
    <Menu
      selectable={false}
      items={noGroup ? menuItems : menuItemsGroup}
      className={cn(
        styles.helpCenterMenu,
        { [styles.helpCenterMenuDrawer]: isDrawer },
        { [styles.helpCenterMenuDropdown]: !isDrawer },
        className,
      )}
      mode="vertical"
    />
  );
};
