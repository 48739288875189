import React from 'react';
import Head from 'next/head';
import { useRouter } from 'next/router';

import { MetaDataInterface } from '@interfaces/metaData';
import { removeEndSlash } from '@utils/removeEndSlash';

const MetaData: React.FC<MetaDataInterface> = ({
  title,
  image = 'https://apps-static-files.s3.eu-west-1.amazonaws.com/app-assets/images/theboatdb-og-image.jpg',
  description = `Full specifications database for boats, sailboats and yachts. 
    Search and compare multiple boats, performance ratios etc. Benchmark your boat. Try it for FREE!`,
  author = 'Marinedatacloud',
  copyright = 'Marinedatacloud',
  siteName = 'Marine Data Cloud LTD',
  keyword = ['boat management and maintenance app', 'yacht management and maintenance app'],
  generator = 'NextJS',
  robots = 'index, follow',
  ogType = 'website',
  twitterCard = 'summary',
}) => {
  const { locale, asPath } = useRouter();

  const baseUrl = removeEndSlash(process.env.THEBOATDB_URL);

  const canonical = `${baseUrl}${asPath}`;

  const lang = locale || 'en';

  const titleWithBrandName = `${title} – ${process.env.NEXT_PUBLIC_APP_NAME}`;

  return (
    <>
      <Head>
        <title>{titleWithBrandName}</title>
        <meta name="title" content={title} />
        <meta name="description" content={description} />
        <link rel="canonical" href={canonical} />
        <link rel="author" href="humans.txt" />
        <link rel="manifest" href="/manifest.json" />
        <meta name="author" content={author} />
        <meta name="keyword" content={keyword.join(', ')} />
        <meta name="copyright" content={copyright} />
        <meta name="locale" content={lang} />
        <meta name="generator" content={generator} />
        <meta name="base_url" content={process.env.THEBOATDB_URL} />
        <meta name="robots" content={robots} />
        <meta name="language" content={lang} />
        <meta name="twitter:widgets:csp" content="on" />
        <meta name="twitter:card" content={twitterCard} />
        <meta name="twitter:title" content={title} />
        <meta name="twitter:description" content={description} />
        <meta name="twitter:image" content={image} />
        <meta name="twitter:url" content={canonical} />
        <meta property="og:type" content={ogType} />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
        <meta property="og:image" content={image} />
        <meta property="og:url" content={canonical} />
        <meta property="og:site_name" content={siteName} />
        <meta property="og:locale" content={lang} />

        <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no" />
      </Head>
    </>
  );
};

export default MetaData;
