import { TWO_HOURS_IN_MILLISECONDS, TWO_MINUTES_IN_MILLISECONDS } from 'mdc-constants';
import { useGraphQLClient } from 'providers';
import { useGetAlerts, GetAlertsVariables } from '../graphql';
import useAccessToken from './useAccessToken';

export default function useAlerts(variables?: GetAlertsVariables) {
  const { data: accessToken } = useAccessToken();
  const { graphQLClient } = useGraphQLClient(accessToken);

  const queryInfo = useGetAlerts(
    graphQLClient,
    variables,
    {
      cacheTime: TWO_HOURS_IN_MILLISECONDS,
      staleTime: TWO_MINUTES_IN_MILLISECONDS,

      keepPreviousData: true,
      enabled: !!accessToken,
      refetchOnMount: true,
    },
  );

  return {
    ...queryInfo,
  };
}
