import { useGraphQLClient } from 'providers';
import useAccessToken from './useAccessToken';
import { useDeleteChecklist } from '../graphql';

export default function useDeleteChecklistMutation() {
  const { data: accessToken } = useAccessToken();
  const { graphQLClient } = useGraphQLClient(accessToken);

  const queryInfo = useDeleteChecklist(graphQLClient);

  return {
    ...queryInfo,
  };
}
