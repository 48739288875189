import { useGraphQLClient } from 'providers';
import useAccessToken from './useAccessToken';
import { useCopyChecklist } from '../graphql';

export default function useCopyChecklistMutation() {
  const { data: accessToken } = useAccessToken();
  const { graphQLClient } = useGraphQLClient(accessToken);

  const queryInfo = useCopyChecklist(graphQLClient);

  return {
    ...queryInfo,
  };
}
