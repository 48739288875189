import { FC, useEffect, useMemo } from 'react';
import { AnnouncementAlertsProps } from './AnnouncementAlerts.props';
import { useAnnouncements } from 'services/hooks/queries/useAnnouncements';
import AnnouncementLevel from './components/AnnouncementLevel.component';
import dayjs, { extend } from 'dayjs';
import { useRemoveExpiredIdsFromLocalStorage } from '@marine/lib';
import utc from 'dayjs/plugin/utc';

extend(utc);

const AnnouncementAlerts: FC<AnnouncementAlertsProps> = ({ appCode }) => {
  const currentDate = useMemo(() => dayjs.utc().toISOString(), []);
  const removeExpiredIdsFromLocalStorage = useRemoveExpiredIdsFromLocalStorage();

  const { data: maintenanceData, isFetched } = useAnnouncements({ appCode: appCode, expirationDate: currentDate });

  useEffect(() => {
    const fetchedIds = maintenanceData?.announcements?.data?.map((announcement) => announcement?.id);

    if (isFetched) {
      removeExpiredIdsFromLocalStorage(fetchedIds);
    }
  }, [maintenanceData?.announcements?.data, removeExpiredIdsFromLocalStorage, isFetched]);

  return (
    <div>
      {maintenanceData?.announcements?.data.map((announcement: any) => (
        <AnnouncementLevel maintenanceData={announcement} key={announcement?.id} />
      ))}
    </div>
  );
};

export { AnnouncementAlerts };
