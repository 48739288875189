import { Limit } from '../graphql';
import useLimits from './queries/useLimits';
import { useMemo } from 'react';

const useLimitByName = (
  limitName: string,
):
  | (Limit & {
      isLoadingLimits: boolean;
    })
  | undefined => {
  const { data: limitsData, isLoading: isLoadingLimits } = useLimits();

  const limitItem: Limit | undefined = useMemo(
    () => limitsData?.limits?.find((item) => item?.name === limitName),
    [limitName, limitsData?.limits],
  );

  return { ...limitItem, isLoadingLimits: isLoadingLimits };
};

export default useLimitByName;
