import { useGraphQLClient } from 'providers';
import useAccessToken from './useAccessToken';
import { useUnfollowChecklist } from '../graphql';

export default function useUnfollowChecklistMutation() {
  const { data: accessToken } = useAccessToken();
  const { graphQLClient } = useGraphQLClient(accessToken);

  const queryInfo = useUnfollowChecklist(graphQLClient);

  return {
    ...queryInfo,
  };
}
