import { useGraphQLClient } from 'providers';
import { useAddChecklist } from '../graphql';
import useAccessToken from './useAccessToken';

export default function useAddChecklistMutation() {
  const { data: accessToken } = useAccessToken();
  const { graphQLClient } = useGraphQLClient(accessToken);

  const mutationInfo = useAddChecklist(graphQLClient);

  return {
    ...mutationInfo,
  };
}
