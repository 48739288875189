import { FC, useCallback, useMemo } from 'react';
import { AnnouncementLevelProps } from '../AnnouncementAlerts.props';
import { Button, Modal } from 'antd';
import { useAddToDismissedAnnouncements, useDismissedAnnouncement } from '@marine/lib';
import AlertAnnouncement from './AlertAnnouncement.component';

const MaintenanceLevel: FC<AnnouncementLevelProps> = ({ maintenanceData }) => {
  const maintenanceLevel = useMemo(() => maintenanceData?.attributes.level, [maintenanceData?.attributes.level]);

  const dismissedAnnouncements = useDismissedAnnouncement();
  const addToDismissed = useAddToDismissedAnnouncements();

  const appIsDismissed = useMemo(
    () => dismissedAnnouncements?.some((dismissedAnnouncement) => dismissedAnnouncement === maintenanceData?.id),
    [dismissedAnnouncements, maintenanceData?.id],
  );

  const handleAlertClose = useCallback(() => {
    addToDismissed(maintenanceData?.id);
  }, [addToDismissed, maintenanceData?.id]);

  if (appIsDismissed) {
    return null;
  }

  if (maintenanceLevel === 'info' || maintenanceLevel === 'warning') {
    return (
      <AlertAnnouncement
        alertType={maintenanceLevel === 'info' ? 'info' : 'warning'}
        announcementData={maintenanceData?.attributes}
        closable={true}
        handleAlertClose={handleAlertClose}
      />
    );
  }

  if (maintenanceLevel === 'announcement') {
    return (
      <Modal
        closable={true}
        open={!appIsDismissed}
        title={maintenanceData?.attributes?.title}
        onCancel={() => handleAlertClose()}
        okText={maintenanceData?.attributes?.action_title || undefined}
        onOk={() =>
          maintenanceData?.attributes?.action_link
            ? window.open(maintenanceData?.attributes?.action_link, '_blank', 'noopener,noreferrer')
            : handleAlertClose()
        }
        width={648}
      >
        <div dangerouslySetInnerHTML={{ __html: maintenanceData?.attributes?.description }} />
      </Modal>
    );
  }

  if (maintenanceLevel === 'maintenance') {
    return (
      <Modal
        closable={false}
        open={!appIsDismissed}
        footer={null}
        width={648}
        title={maintenanceData?.attributes?.title}
      >
        <div>
          <div dangerouslySetInnerHTML={{ __html: maintenanceData?.attributes?.description }} />
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            {maintenanceData?.attributes?.action_title ? (
              <Button
                type="primary"
                onClick={() => window.open(maintenanceData?.attributes?.action_link, '_blank', 'noopener,noreferrer')}
              >
                {maintenanceData?.attributes?.action_title}
              </Button>
            ) : null}
          </div>
        </div>
      </Modal>
    );
  }

  return null;
};

export default MaintenanceLevel;
