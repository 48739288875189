import { TWO_HOURS_IN_MILLISECONDS, TWO_MINUTES_IN_MILLISECONDS } from 'mdc-constants';
import useAccessToken from './useAccessToken';
import { useGraphQLClient } from 'providers';
import { useGetUnpaginatedCosts, GetUnpaginatedCostsVariables } from '../graphql';

export default function useUnpaginatedCosts(variables?: GetUnpaginatedCostsVariables) {
  const { data: accessToken } = useAccessToken();
  const { graphQLClient } = useGraphQLClient(accessToken);

  const queryInfo = useGetUnpaginatedCosts(graphQLClient, variables, {
    cacheTime: TWO_HOURS_IN_MILLISECONDS,
    staleTime: TWO_MINUTES_IN_MILLISECONDS,
    keepPreviousData: true,
    refetchOnReconnect: true,
    refetchOnWindowFocus: false,
    enabled: !!accessToken,
  });

  return queryInfo;
}
