import { TWO_HOURS_IN_MILLISECONDS, TWO_MINUTES_IN_MILLISECONDS } from 'mdc-constants';
import { useGraphQLClient } from 'providers';
import { useInfiniteGetCosts, GetCostsVariables } from '../graphql';
import useAccessToken from './useAccessToken';

export default function useInfiniteCostHistory(variables?: GetCostsVariables, itemId?: string) {
  const { data: accessToken } = useAccessToken();
  const { graphQLClient } = useGraphQLClient(accessToken);

  const queryInfo = useInfiniteGetCosts('page', graphQLClient, variables, {
    cacheTime: TWO_HOURS_IN_MILLISECONDS,
    staleTime: TWO_MINUTES_IN_MILLISECONDS,
    suspense: false,
    enabled: !!accessToken && !!itemId,

    getNextPageParam: ({ costs }) => {
      const { currentPage, hasMorePages } = costs?.paginatorInfo || {};

      if (hasMorePages && currentPage) {
        return currentPage + 1;
      }

      return undefined;
    },
  });

  return {
    ...queryInfo,
  };
}
