import { TWO_HOURS_IN_MILLISECONDS, TWO_MINUTES_IN_MILLISECONDS } from 'mdc-constants';
import { useGraphQLClient } from 'providers';
import { useGetCosts, GetCostsVariables } from '../graphql';
import useAccessToken from './useAccessToken';

export default function useCosts(variables?: GetCostsVariables) {
  const { data: accessToken } = useAccessToken();
  const { graphQLClient } = useGraphQLClient(accessToken);

  const queryInfo = useGetCosts(graphQLClient, variables, {
    cacheTime: TWO_HOURS_IN_MILLISECONDS,
    staleTime: TWO_MINUTES_IN_MILLISECONDS,

    keepPreviousData: true,
    enabled: !!accessToken,
    refetchOnMount: true,
  });

  return queryInfo;
}
